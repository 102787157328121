<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Quản lí Activation Code</h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogActivationCode"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Tạo Code</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="6" class="d-flex pt-5">
            <label class="text-body-1 font-weight-medium mb-6"
              >Trang thái activation code:</label
            >
            <v-radio-group class="mt-0 pt-0" v-model="status_code_filter" row>
              <v-radio label="Active" :value="1"></v-radio>
              <v-radio label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6">
            <b-form-input
              class=""
              style="float: right; width: 300px"
              placeholder="Search Code"
              v-model="input_search"
              @keyup.enter="actionSearchCode()"
            ></b-form-input>
          </v-col>
          <v-col cols="6">
            <v-pagination
              class=""
              color="#00BCD4"
              style="float: left"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Code</th>
                    <th scope="col">Type</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Used</th>
                    <th scope="col">Expired</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(code, i) in activation_codes">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td class="">
                        <p
                          style="max-width: 120px; word-break: break-word"
                          class="text-dark-75 font-weight-bolder font-size-lg"
                        >
                          {{ code.code }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder font-size-lg light-blue--text lighten-1"
                          v-if="code.type === 1"
                        >
                          Mã dùng chung
                        </p>
                        <p
                          class="text-dark-75 font-weight-bolder font-size-lg teal--text lighten-1"
                          v-if="code.type === 2"
                        >
                          Mã cá nhân
                        </p>
                      </td>
                      <td>
                        <p class="text-dark-75 font-weight-bolder font-size-lg">
                          {{ code.quantity }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder font-size-lg"
                          v-if="code.used <= 0"
                        >
                          {{ code.used }}
                        </p>
                        <p
                          class="text-dark-75 font-weight-bolder font-size-lg blue--text text-decoration-underline cursor-pointer"
                          v-if="code.used > 0"
                          @click="getListUserUsedCode(code.id)"
                        >
                          {{ code.used }}
                        </p>
                      </td>
                      <td>
                        <p class="text-dark-75 font-weight-bolder font-size-lg">
                          {{ code.expired_at }}
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-warning btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editCode(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
          <v-col cols="6">
            <v-pagination
              class="mt-5"
              color="#00BCD4"
              style="float: left"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </div>

    <ActivationCodeForm
      :show_dialog="dialogActivationCode"
      :type_form="type_form"
      :student_tags="student_tags"
      :benefit_tags="benefit_tags"
      :activation_code="activation_code"
      @setDialog="(e) => (this.dialogActivationCode = e)"
      @resetData="resetListCode"
    />

    <ListUserUsed
      :show_dialog="dialogListUserUseCode"
      :dataUser="listUserUseCode"
      @setDialog="(e) => (this.dialogListUserUseCode = e)"
    />
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import ActivationCodeForm from "./modal/ActivationCodeForm";
import Swal from "sweetalert2";
import ListUserUsed from "./modal/ListUserUsed";

export default {
  name: "ActivationCodeList",
  components: {
    ListUserUsed,
    ActivationCodeForm,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      activation_codes: [],
      activation_code: {},
      data_pagination: {
        current_page: 1,
        total_pages: 1,
      },
      input_search: "",
      timer_query: null,
      dialogActivationCode: false,
      type_form: "",
      student_tags: [],
      status_code_filter: 1,
      programme_tags: [],
      benefit_tags: [],
      dialogListUserUseCode: false,
      listUserUseCode: [],
    };
  },
  watch: {
    "data_pagination.current_page": async function (page) {
      this.getAllActivationCode(page, this.input_search);
    },
    status_code_filter() {
      this.getAllActivationCode(1, this.input_search);
    },
  },
  created() {
    this.getAllActivationCode();
    // this.getAllStudent();
    this.getAllBenefit();
  },
  methods: {
    getProgrammeLimit() {
      let vm = this;
      ApiService.get("prep-app/programme/limit")
        .then(function (res) {
          if (res.status === 200) {
            vm.programme_tags = res.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async getAllBenefit() {
      let vm = this;
      await ApiService.get("prep-app/benefit").then(function (response) {
        if (response.status === 200) {
          vm.benefit_tags = response.data;
        }
      });
    },
    openDiaLogActivationCode() {
      this.type_form = "add";
      this.dialogActivationCode = true;
    },
    async getAllActivationCode(page = 1, query = "") {
      try {
        this.is_call_api = true;
        let url = "prep-app/activation-code?page=" + page + "&code=" + query;
        url += url + "&status_active=" + this.status_code_filter;
        let res = await ApiService.get(url);
        if (res.status === 200) {
          this.activation_codes = res.data.data;
          this.data_pagination = res.data.meta.pagination;
          this.is_call_api = false;
        }
      } catch (error) {
        this.is_call_api = false;
        console.log(error);
      }
    },
    // async getAllStudent() {
    //   let vm = this;
    //   await ApiService.get("prep-app/student/limit").then(function (response) {
    //     if (response.status === 200) {
    //       let students = response.data;
    //       vm.student_tags = students;
    //       for (let i = 0; i < students.length; i++) {
    //         vm.student_tags[i].label =
    //           students[i].phone + "(" + students[i].name + ")";
    //       }
    //     }
    //   });
    // },
    editCode(index) {
      this.activation_code = this.activation_codes[index];
      this.type_form = "update";
      this.dialogActivationCode = true;
    },
    inactiveCode(activation_code_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/activation-code/" + activation_code_id)
            .then(function (res) {
              if (res.status === 200) {
                vm.$toasted.success("Hủy kích hoạt thành công!!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.resetListCode();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    actionSearchCode() {
      let query = this.input_search;
      let vm = this;
      if (this.timer_query) {
        clearTimeout(this.timer_query);
        this.timer_query = null;
      }
      this.timer_query = setTimeout(() => {
        vm.getAllActivationCode(1, query);
      }, 1000);
    },
    resetListCode() {
      this.getAllActivationCode(
        this.data_pagination.current_page,
        this.input_search
      );
    },
    async getListUserUsedCode(activation_code_id) {
      let vm = this;

      vm.is_call_api = true;
      await ApiService.get(
        "prep-app/activation-code/" + activation_code_id + "/list-user-used"
      ).then(function (response) {
        if (response.status === 200) {
          vm.listUserUseCode = response.data;
          vm.dialogListUserUseCode = true;
          vm.is_call_api = false;
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}

.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
