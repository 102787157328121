<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogActivationCode"
        max-width="1400px"
        scrollable
        persistent
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'">Tạo Code</span>
              <span class="headline" v-else>Sửa Code</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogActivationCode = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Code:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-text-field
                        dense
                        v-model="code_input"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Type:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-radio-group v-model="type_input" row class="mt-0">
                        <v-radio label="Dùng chung" :value="1"></v-radio>
                        <v-radio label="Cá nhân" :value="2"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Kích hoạt:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4 d-flex">
                      <v-radio-group v-model="active_input" row class="mt-0">
                        <v-radio label="Có" :value="1"></v-radio>
                        <v-radio label="Không" :value="0"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Số lượng:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-text-field
                        dense
                        v-model="quantity_input"
                        type="number"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="type_input === 2">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Owner:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
<!--                      <v-autocomplete-->
<!--                        v-model="owner_id_input"-->
<!--                        :items="student_tags"-->
<!--                        item-text="label"-->
<!--                        item-value="id"-->
<!--                        outlined-->
<!--                        dense-->
<!--                      />-->
                      <student-search @setStudentObj="(e) => this.setOwnerToInput(e)"></student-search>
                      <template v-if="owner_obj_input">
                        <p class="text-body-1 mb-4">{{owner_obj_input.phone}} - {{owner_obj_input.name}}</p>
                      </template>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Ngày hết hạn:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <date-picker
                        style="width: 40%"
                        class=""
                        v-model="expired_at_input"
                        value-type="format"
                        format="YYYY-MM-DD"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="pt-4">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Benefit:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-autocomplete
                        v-model="benefit_ids"
                        :items="benefit_tags"
                        item-text="name"
                        item-value="id"
                        outlined
                        chips
                        deletable-chips
                        multiple
                      />
                      <div style="width: 100%" class="d-flex">
                        <div style="width: 35%" class="d-flex">
                          <label class="text-h6">Duration(ngày):</label>
                          <v-text-field
                            dense
                            v-model="duration_benefit_input"
                            type="number"
                            outlined
                          ></v-text-field>
                        </div>
                        <div style="width: 35%" class="d-flex ml-4">
                          <label class="text-h6">Giới hạn tính năng:</label>
                          <v-switch
                            class="mt-0 pt-0"
                            v-model="is_restricted_benefit"
                            :label="is_restricted_benefit ? 'Có' : 'Không'"
                          ></v-switch>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditCode(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditCode(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogActivationCode = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
import StudentSearch from "../../../../components/Selects/StudentSearch";

export default {
  name: "ActivationCodeForm",
  components: {
    StudentSearch,
    Loading: () => import("vue-loading-overlay"),
    // StudentSelect: () => import("@/components/Selects/StudentSelect"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    type_form: {
      type: String,
      default: "",
    },
    student_tags: {
      type: Array,
      default: () => [],
    },
    activation_code: {},
    benefit_tags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      is_call_api: false,
      code_input: null,
      type_input: null,
      active_input: null,
      owner_id_input: null,
      quantity_input: null,
      expired_at_input: null,
      activation_code_id: null,
      benefit_ids: [],
      duration_benefit_input: null,
      is_restricted_benefit: false,
      owner_obj_input: null,
    };
  },
  computed: {
    dialogActivationCode: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    dialogActivationCode(val) {
      if (val) {
        this.setDataForm();
      }
    },
  },
  methods: {
    setDataForm() {
      if (this.type_form === "add") {
        this.code_input = "";
        this.type_input = 1;
        this.active_input = 1;
        this.owner_id_input = null;
        this.quantity_input = null;
        this.expired_at_input = null;
        this.benefit_ids = [];
        this.duration_benefit_input = null;
        this.is_restricted_benefit = false;
        this.owner_obj_input = null;
      } else {
        let data = JSON.parse(JSON.stringify(this.activation_code));
        this.activation_code_id = data.id;
        this.code_input = data.code;
        this.type_input = data.type;
        this.active_input = data.active;
        this.owner_id_input = data.owner_id;
        this.owner_obj_input = data.owner ? data.owner : null;
        this.quantity_input = data.quantity;
        this.expired_at_input = data.expired_at;

        let benefits = JSON.parse(JSON.stringify(data.benefits));
        this.benefit_ids = benefits.map((e) => e.id);
        this.duration_benefit_input =
          benefits.length <= 0 ? null : benefits[0].duration;
        let is_restricted =
          benefits.length <= 0 ? 0 : benefits[0].is_restricted;
        this.is_restricted_benefit = is_restricted === 1;
      }
    },
    validateData() {
      let flat = true;
      if (this.code_input === "" || this.code_input == null) {
        this.$toasted.error("Chưa nhập code !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.type_input == null) {
        this.$toasted.error("Hãy chọn type !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.active_input == null) {
        this.$toasted.error("Hãy chọn kích hoạt !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (
        this.quantity_input === "" ||
        this.quantity_input == null ||
        this.quantity_input <= 0
      ) {
        this.$toasted.error("Hãy nhập quantity và phải lớn hơn 0!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.expired_at_input == null) {
        this.$toasted.error("Hãy nhập thời gian hết hạn !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.expired_at_input != null) {
        if (this.type_form === "add") {
          if (
            Date.parse(this.expired_at_input) <
            Date.parse(
              this.$options.filters.convertTimestampToDate(Date.now() / 1000)
            )
          ) {
            this.$toasted.error(
              "Thời gian hết hạn không thể là thời gian trong quá khứ !!",
              {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              }
            );
            flat = false;
          }
        }
      }
      if (this.type_input === 2) {
        if (this.owner_id_input == null) {
          this.$toasted.error("Hãy chọn chủ sở hữu !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      if (this.benefit_ids.length <= 0) {
        this.$toasted.error("Hãy chọn benefit !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (
        this.duration_benefit_input === "" ||
        this.duration_benefit_input == null ||
        this.duration_benefit_input <= 0
      ) {
        this.$toasted.error("Hãy nhập duration và phải lớn hơn 0 !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      return flat;
    },
    btnAddOrEditCode(type) {
      let vm = this;
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let data = {
        code: this.code_input,
        type: this.type_input,
        active: this.active_input,
        quantity: this.quantity_input,
        expired_at: this.expired_at_input,
      };
      if (this.type_input === 2) data.owner_id = this.owner_id_input;
      if (this.benefit_ids.length > 0) {
        data.benefit_ids = this.benefit_ids;
        data.duration_benefit = this.duration_benefit_input;
        data.is_restricted_benefit = this.is_restricted_benefit ? 1 : 0;
      }
      this.is_call_api = true;
      if (type === 1) {
        ApiService.post("prep-app/activation-code", data)
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Thêm thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetData");
              vm.is_call_api = false;
              vm.dialogActivationCode = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        ApiService.put(
          "prep-app/activation-code/" + this.activation_code_id,
          data
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Sửa thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetData");
              vm.is_call_api = false;
              vm.dialogActivationCode = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    setOwnerToInput(student){
      this.owner_id_input = student.id;
      this.owner_obj_input = student;
    },
  },
};
</script>

<style scoped></style>
