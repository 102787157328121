<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialogListUser"
        max-width="1400px"
        scrollable
        persistent
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Danh sách user đã dùng code</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogListUser = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div class="table-responsive">
                    <table
                      class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
                    >
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Phone</th>
                          <th scope="col">Ngày kích hoạt</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(user, i) in dataUser">
                          <tr :key="i">
                            <td scope="row">{{ i + 1 }}</td>
                            <td class="">
                              <p
                                class="text-dark-75 font-weight-bolder font-size-lg"
                              >
                                {{ user.name }}
                              </p>
                            </td>
                            <td>
                              <p
                                class="text-dark-75 font-weight-bolder font-size-lg"
                              >
                                {{ user.email }}
                              </p>
                            </td>
                            <td>
                              <p
                                class="text-dark-75 font-weight-bolder font-size-lg"
                              >
                                {{ user.phone }}
                              </p>
                            </td>
                            <td>
                              <p
                                class="text-dark-75 font-weight-bolder font-size-lg"
                              >
                                {{ user.activated_at }}
                              </p>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogListUser = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ListUserUsed",
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    dataUser: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    dialogListUser: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}
</style>
